@import url("//hello.myfonts.net/count/381654");
@font-face {
  font-family: 'HelveticaNeueLTPro-Roman';
  src: url("fonts/381654_0_0.eot");
  src: url("fonts/381654_0_0.eot?#iefix") format("embedded-opentype"), url("fonts/381654_0_0.woff2") format("woff2"), url("fonts/381654_0_0.woff") format("woff"), url("fonts/381654_0_0.ttf") format("truetype"); }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  text-align: left; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400; }

p {
  margin-top: 0;
  margin-bottom: 0; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #000;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #262626;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

body {
  font-family: 'HelveticaNeueLTPro-Roman', sans-serif;
  font-weight: 400;
  background: #fff;
  line-height: 20px;
  font-size: 16px; }

strong, b {
  font-weight: 800; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0;
  line-height: normal; }

h1, .h1 {
  font-size: 26px;
  line-height: 34px;
  font-weight: 400; }

.underline2 {
  border-bottom: solid 2px #000; }

.topline {
  border-top: solid 1px #000; }

.topline2 {
  border-top: solid 2px #000; }

h2, .h2 {
  font-size: 26px;
  font-weight: 400;
  line-height: 34px;
  padding-top: 5px; }
  h2.small, .h2.small {
    font-size: 16px;
    font-weight: normal; }
  h2 small, .h2 small {
    font-size: 16px;
    font-weight: normal; }
  h2.underline, .h2.underline {
    border-bottom: solid 1px #000; }
  h2.underline2, .h2.underline2 {
    border-bottom: solid 2px #000; }
  h2.topline, .h2.topline {
    border-top: solid 1px #000; }
    h2.topline.first, .h2.topline.first {
      border-top: 0; }
  h2.topline2, .h2.topline2 {
    border-top: solid 2px #000; }
  h2.teambar a, .h2.teambar a {
    float: right; }
    h2.teambar a svg, .h2.teambar a svg {
      height: 20px;
      width: 20px;
      margin-left: 10px; }

em,
i,
.italic {
  font-style: italic; }

strong,
b,
.bold {
  font-weight: 700; }

img {
  display: block;
  max-width: 100%;
  min-width: 100%; }

p {
  /*margin-bottom: $default-margin;*/
  margin-bottom: 0; }
  p:last-child {
    margin-bottom: 0; }

a, a:link, a:visited, a:active {
  color: #000;
  text-decoration: none; }
  a:hover, a:link:hover, a:visited:hover, a:active:hover {
    /*color: lighten($default-link-color, 30);*/
    text-decoration: none; }

.titel_einzug {
  margin-left: 20px; }

.proj-label {
  vertical-align: top;
  white-space: nowrap;
  width: 155px; }

.cb {
  clear: both; }

.pointer {
  cursor: pointer; }

.footer-img {
  height: 45px;
  width: auto;
  min-width: auto; }

@media screen and (max-width: 600px) {
  body {
    line-height: 23px;
    font-size: 16px; }
  h1, .h1 {
    font-size: 20px;
    line-height: 25px; }
  h2, .h2 {
    font-size: 20px;
    line-height: 25px; } }

/** VARIABLES
===================================*/
/** RESET AND LAYOUT
===================================*/
.bx-wrapper {
  position: relative;
  margin-bottom: 60px;
  padding: 0;
  *zoom: 1;
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.bx-wrapper img {
  max-width: 100%;
  display: block; }

.bxslider {
  margin: 0;
  padding: 0; }

ul.bxslider {
  list-style: none; }

.bx-viewport {
  /*fix other elements on the page moving (on Chrome)*/
  -webkit-transform: translatez(0); }

/** THEME
===================================*/
.bx-wrapper {
  -moz-box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  box-shadow: 0 0 5px #ccc;
  border: 5px solid #fff;
  background: #fff; }

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: -30px;
  width: 100%; }

/* LOADER */
.bx-wrapper .bx-loading {
  min-height: 50px;
  background: url("images/bx_loader.gif") center center no-repeat #ffffff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000; }

/* PAGER */
.bx-wrapper .bx-pager {
  text-align: center;
  font-size: .85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
  padding-top: 20px; }

.bx-wrapper .bx-pager.bx-default-pager a {
  background: #666;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px; }

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:focus {
  background: #000; }

.bx-wrapper .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  vertical-align: bottom;
  *zoom: 1;
  *display: inline; }

.bx-wrapper .bx-pager-item {
  font-size: 0;
  line-height: 0; }

/* DIRECTION CONTROLS (NEXT / PREV) */
.bx-wrapper .bx-prev {
  left: 10px;
  background: url("images/controls.png") no-repeat 0 -32px; }

.bx-wrapper .bx-prev:hover,
.bx-wrapper .bx-prev:focus {
  background-position: 0 0; }

.bx-wrapper .bx-next {
  right: 10px;
  background: url("images/controls.png") no-repeat -43px -32px; }

.bx-wrapper .bx-next:hover,
.bx-wrapper .bx-next:focus {
  background-position: -43px 0; }

.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 9999; }

.bx-wrapper .bx-controls-direction a.disabled {
  display: none; }

/* AUTO CONTROLS (START / STOP) */
.bx-wrapper .bx-controls-auto {
  text-align: center; }

.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url("images/controls.png") -86px -11px no-repeat;
  margin: 0 3px; }

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active,
.bx-wrapper .bx-controls-auto .bx-start:focus {
  background-position: -86px 0; }

.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url("images/controls.png") -86px -44px no-repeat;
  margin: 0 3px; }

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active,
.bx-wrapper .bx-controls-auto .bx-stop:focus {
  background-position: -86px -33px; }

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%; }

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px; }

/* IMAGE CAPTIONS */
.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666;
  background: rgba(80, 80, 80, 0.75);
  width: 100%; }

.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: .85em;
  padding: 10px; }

article.projects {
  width: 45%;
  width: calc(50% - 15px);
  margin: 0 30px 30px 0;
  float: left;
  position: relative; }
  article.projects .image-container {
    background-color: #DCDCDC;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 300px;
    margin-bottom: 15px; }
    article.projects .image-container.grouplink {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      line-height: 34px;
      color: #fff;
      text-align: center; }
  article.projects h2 {
    font-size: 26px;
    line-height: 34px;
    width: 100%; }
  article.projects:nth-of-type(2n + 2) {
    margin-right: 0; }

article.projects-index {
  height: auto;
  margin: 0 30px 30px 0;
  border-top: solid 2px #000;
  /*
        .arrow{
            width: 100px;
            position: absolute;
            bottom: -24px;
            right: 0;
            display: none;
        }*/
  /*
        &:hover, &.simulate-hover{
            -webkit-user-select: none;
            -webkit-touch-callout: none;    
            
            .arrow{
                display: block;
            }
        }*/ }
  article.projects-index .image-container {
    position: absolute;
    bottom: 0;
    background-color: #DCDCDC;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 275px;
    margin-bottom: 0;
    margin-top: 15px; }
  article.projects-index h2 {
    font-size: 26px;
    line-height: 34px;
    margin: 10px 0 5px 0;
    /*
            width: 80%;
            width: calc(100% - 100px);
            */ }
  article.projects-index .lead {
    /*
            width: 80%;
            width: calc(100% - 100px);
            */
    margin-bottom: 285px; }

article.projects-archive {
  width: 22%;
  width: calc(25% - 30px);
  height: auto;
  margin: 0 30px 15px 0;
  float: left;
  position: relative;
  /*
        &:nth-child(4n + 4){
            margin-right: 0;
        }
        */ }
  article.projects-archive .image-container {
    background-color: #DCDCDC;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 140px;
    margin-bottom: 7.5px; }
  article.projects-archive h2 {
    width: 100%;
    font-size: 16px;
    line-height: 18px; }
  article.projects-archive:nth-child(2n + 2) {
    margin-right: 30px; }

article.projects-detail img, article.news-detail img {
  margin: auto;
  margin-bottom: 30px; }

article.projects-detail h2, article.news-detail h2 {
  margin-bottom: 30px; }

article.projects-detail .info, article.news-detail .info {
  padding: 20px 0;
  display: inline-block;
  font-size: 36px; }
  article.projects-detail .info svg, article.news-detail .info svg {
    height: 27px;
    width: 27px; }

article.projects-detail p.description, article.news-detail p.description {
  column-count: 2;
  column-gap: 60px;
  margin-bottom: 60px;
  /*display: none;*/ }

article.projects-detail p.docs, article.news-detail p.docs {
  /*margin-top: -$default-margin;*/
  margin-bottom: 60px; }

article.events {
  display: flex;
  /*border-bottom: solid 1px $black;*/
  padding-bottom: 30px;
  /*margin-bottom: $default-margin;*/
  position: relative;
  padding-top: 18px; }
  article.events:before {
    content: '';
    width: 8%;
    height: 3px;
    background: #000;
    position: absolute;
    top: 0;
    left: 0; }
  article.events .img {
    flex: 200px 0 0;
    margin-right: 30px;
    margin-top: 7px; }
    article.events .img .image-container {
      background-color: #DCDCDC;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 140px;
      margin-bottom: 7.5px; }
  article.events .event {
    flex-grow: 2;
    margin-right: 30px; }
    article.events .event h2 {
      margin-bottom: 10px; }
  article.events .date {
    flex: 200px 0 0;
    margin-right: 30px;
    margin-top: 45px; }
    article.events .date h3 {
      margin-bottom: 10px; }
    article.events .date p {
      margin-bottom: 10px; }

article .fixed-row {
  width: 100%;
  display: block;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden; }

article.news {
  background: #fff;
  width: 33%;
  width: calc(33% - 30px);
  margin: 0 30px 30px 0;
  float: none; }
  article.news h2 {
    position: relative;
    /*padding-top: 18px;*/
    padding-top: 5px;
    margin-bottom: 30px; }
    article.news h2 em {
      font-size: 16px; }
    article.news h2:before {
      content: '';
      width: 15%;
      height: 3px;
      background: #000;
      position: absolute;
      top: 0;
      left: 0; }
  article.news img {
    margin-bottom: 15px;
    min-width: 100%;
    max-width: 100%; }
  article.news:nth-child(3n + 3) {
    margin-right: 0; }

article.default h2 {
  margin-bottom: 30px; }

article.default p {
  font-size: 16px;
  /*margin-bottom: $default-margin;*/ }
  article.default p a {
    text-decoration: underline;
    cursor: pointer; }

article.default img {
  margin: auto;
  margin-bottom: 30px; }

article.default label {
  font-size: 12px;
  position: relative; }
  article.default label span {
    top: -45px;
    position: absolute;
    display: block;
    white-space: nowrap; }

.grid {
  width: calc(100% + 30px);
  overflow: hidden; }

.gallery p {
  font-size: 16px;
  line-height: 20px; }

.network {
  align-items: center;
  padding-bottom: 30px;
  border-bottom: solid 1px #000; }
  .network a {
    margin-right: 30px;
    flex: 320px 0 0; }
  .network img {
    width: 320px; }
  .network:last-child {
    border-bottom: 0; }

/******************************************************************************/
/*
@media screen and (max-width:1250px) {
    article{
        &.projects{       
            height: 36vw;
            
            .image-container{
                height: 34vw;
                height: calc(40vw - 135px);
            }
        }
        &.projects-index{       
            height: 28vw;
            
            .image-container{
                height: 19vw;
                height: calc(21vw - 60px);
                width: 22vw;
            }

        }
        &.projects-archive{       
            height: 22.5vw;

            .image-container{
                height: 19vw;
                height: calc(21vw - 60px);
            }
        }
    }
}
@media screen and (max-width:1000px) {
    article{
        &.projects-index{       
            height: 31vw;
        }
        &.projects-archive{       
            width: 33%;
            width: calc(33.3333% - 30px);
            height: 29vw;
            
            .image-container{
                height: 25vw;
                height: calc(27vw - 60px);
            }
            
            &:nth-child(4n + 4){
                margin-right: $default-margin;
            }
            &:nth-child(3n + 3){
                margin-right: 0;
            }
        }
    }
}
@media screen and (max-width:900px) {
    article{
        &.projects-index{       
            height: 36vw;
        }
        &.news{
            width: 50%;
            width: calc(50% - 30px);

            &:nth-child(3n + 3){
                margin-right: $default-margin;
            }
            &:nth-child(2n + 2){
                margin-right: 0;
            }
        }
    }
}
*/
@media screen and (max-width: 960px) {
  article.projects .image-container {
    height: 33vw;
    height: calc(35vw - 45px); }
  article.projects-index {
    /*height: 28vw;*/ }
    article.projects-index .image-container {
      height: 30vw;
      height: calc(33vw - 45px);
      /*width: 22vw;
                width: calc(28vw - 45px);*/ }
    article.projects-index .lead {
      margin-bottom: 30vw;
      margin-bottom: calc(33vw - 45px + 10px); }
  article.projects-archive {
    width: 33%;
    width: calc(33.3333% - 30px); }
    article.projects-archive .image-container {
      height: 25vw;
      height: calc(27vw - 60px); }
    article.projects-archive:nth-child(4n + 4) {
      margin-right: 30px; }
    article.projects-archive:nth-child(3n + 3) {
      margin-right: 0; }
  article.news {
    width: 50%;
    width: calc(50% - 30px); }
    article.news:nth-child(3n + 3) {
      margin-right: 30px; }
    article.news:nth-child(2n + 2) {
      margin-right: 0; } }

@media screen and (max-width: 800px) {
  article.events {
    flex-wrap: wrap; }
    article.events .img {
      order: 2;
      flex: 33% 0 0;
      flex: calc(33% - 30px) 0 0; }
      article.events .img .image-container {
        height: 19vw; }
    article.events .event {
      flex: 66% 0 0;
      flex: calc(66% - 30px) 0 0; }
    article.events .date {
      order: 3;
      flex: 66% 0 0;
      flex: calc(66% - 30px) 0 0; } }

@media screen and (max-width: 750px) {
  article {
    /*
        &.projects{
            width: 100%;
            height: 67vw;
            
            .image-container{
                height: 73vw;
                height: calc(73vw - 135px);
            }
        }
        &.projects-index{       
            height: auto;
            
            .image-container{
                position: relative;
                height: 34vw;
                width: 47vw;
            }
        }*/ }
    article.projects-archive {
      width: 50%;
      width: calc(50% - 30px);
      /*
            &:nth-child(3n + 3){
                margin-right: $default-margin;
            }
            &:nth-child(2n + 2){
                margin-right: 0;
            }*/ }
      article.projects-archive .image-container {
        height: 38vw;
        height: calc(40vw - 60px); }
      article.projects-archive:nth-child(3n + 3) {
        margin-right: 30px; }
    article.projects-detail p.description, article.news-detail p.description {
      column-count: 1;
      column-gap: 0; } }

@media screen and (max-width: 600px) {
  article.projects {
    width: 100%;
    margin-right: 0;
    /*height: 70vw;*/ }
    article.projects .image-container {
      height: 90vw;
      height: calc(90vw - 135px); }
    article.projects h2 {
      font-size: 20px;
      line-height: 25px; }
    article.projects:nth-child(2n + 2) {
      margin-right: 0; }
  article.projects-index {
    height: auto;
    margin-right: 0; }
    article.projects-index h2 {
      font-size: 20px;
      line-height: 25px; }
    article.projects-index .image-container {
      position: relative;
      height: 68vw;
      height: calc(70vw - 60px);
      /*width: 47vw;
                width: calc(53vw - 60px);*/ }
    article.projects-index .lead {
      margin-bottom: 0; }
  article.projects-archive {
    margin-right: 0;
    height: auto; }
    article.projects-archive .image-container {
      height: 69vw;
      height: calc(72vw - 60px); }
    article.projects-archive h2 {
      font-size: 20px;
      line-height: 25px; }
    article.projects-archive:nth-child(2n + 2) {
      margin-right: 0; }
    article.projects-archive:nth-child(3n + 3) {
      margin-right: 0; }
  article.news {
    width: 100%;
    margin-right: 0; }
  article.events {
    flex-wrap: wrap; }
    article.events .img {
      order: 3;
      flex: 100% 0 0; }
      article.events .img .image-container {
        height: 62vw; }
    article.events .event {
      flex: 100% 0 0; }
    article.events .date {
      order: 2;
      flex: 100% 0 0; }
  .grid {
    width: 100%; } }

@media screen and (max-width: 500px) {
  article.projects {
    width: 100%;
    /*height: 73vw;*/ }
    article.projects .image-container {
      height: 100vw;
      height: calc(100vw - 135px); }
  article.projects-index {
    height: auto; }
    article.projects-index h2 {
      width: calc(100% - 60px); }
    article.projects-index .image-container {
      height: 73vw;
      height: calc(75vw - 60px); }
    article.projects-index .arrow {
      width: 60px; }
  article.projects-archive {
    margin-right: 0; }
    article.projects-archive .image-container {
      height: 70vw;
      height: calc(72vw - 60px); } }

@media screen and (max-width: 450px) {
  article.projects .image-container {
    height: 105vw;
    height: calc(105vw - 135px); }
  article.projects-archive .image-container {
    height: 70vw;
    height: calc(72vw - 30px); }
  article.projects-index .image-container {
    height: 78vw;
    height: calc(80vw - 60px); } }

.search {
  border-bottom: solid 1px #666;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 10px 0; }
  .search img {
    width: 20px;
    height: 20px;
    display: inline-block;
    min-width: auto;
    margin-right: 10px; }
  .search input {
    border: 0;
    outline: none;
    font-size: 16px;
    width: 80%;
    width: calc(100% - 140px); }
  .search .filterbutton {
    float: right;
    cursor: pointer; }
    .search .filterbutton svg {
      display: inline-block;
      vertical-align: middle;
      height: 20px;
      width: 20px;
      margin-left: 15px; }

.iconmail {
  width: 30px;
  height: 30px;
  fill: #000;
  margin-left: 15px;
  margin-bottom: -5px; }

mark {
  background: #ffc1c1; }

article.team {
  width: 100%;
  border-top: solid 1px #000; }
  article.team h3 {
    font-size: 26px;
    line-height: 34px;
    padding: 5px 0;
    cursor: pointer; }
    article.team h3 .name {
      margin-right: 30px; }
    article.team h3 .description {
      font-size: 16px;
      line-height: 20px; }
  article.team .team-content {
    /*border-top: solid 1px $black;*/
    display: none;
    padding: 15px 0;
    overflow: hidden; }
    article.team .team-content .left {
      float: left;
      width: 30%;
      margin-right: 30px; }
      article.team .team-content .left img {
        width: 100%;
        margin-bottom: 15px; }
    article.team .team-content .right {
      float: left;
      width: 66%;
      width: calc(70% - 30px);
      font-size: 16px;
      line-height: 20px; }
  article.team.no-border {
    border-top: 0; }

.team-location-title {
  padding: 7.5px 0;
  cursor: pointer; }

.team-location {
  display: none;
  padding: 0 0 15px 0;
  overflow: hidden; }

.team-group {
  display: none; }

@media screen and (max-width: 600px) {
  article.team h3 .name {
    margin-right: 0;
    display: block;
    width: 100%; }
  article.team h3 .description {
    line-height: 20px;
    margin-right: 0;
    display: block;
    width: 100%; }
  article.team .team-content .left {
    position: relative;
    float: none;
    width: 100%;
    margin-right: 0;
    padding-top: 30px; }
    article.team .team-content .left img {
      width: 75%; }
    article.team .team-content .left a {
      position: absolute;
      top: 0;
      left: 0; }
  article.team .team-content .right {
    float: none;
    width: 100%;
    margin-bottom: 30px; } }

.bx-wrapper {
  box-shadow: none; }
  .bx-wrapper .bx-controls-direction a {
    width: 30px;
    height: 150px;
    margin-top: -100px; }
  .bx-wrapper img {
    margin: auto;
    /*margin: 0 auto 50px auto;*/ }
  .bx-wrapper .bx-next {
    right: -30px;
    background: url("/dist/right.svg") no-repeat 50% 50%; }
    .bx-wrapper .bx-next:hover, .bx-wrapper .bx-next:focus {
      background-position: 30% 50%; }
  .bx-wrapper .bx-prev {
    left: -30px;
    background: url("/dist/left.svg") no-repeat 50% 50%; }
    .bx-wrapper .bx-prev:hover, .bx-wrapper .bx-prev:focus {
      background-position: 70% 50%; }
  .bx-wrapper .bx-next,
  .bx-wrapper .bx-prev {
    background-size: 30px 30px;
    background-color: transparent;
    width: 30px;
    height: 30px; }
  .bx-wrapper .bx-pager {
    display: none;
    padding-top: 0; }
  .bx-wrapper .bx-caption {
    font-family: 'NeuePlakText-Regular', sans-serif;
    background: #fff;
    /*height: 50px;*/
    position: relative;
    overflow: hidden; }
    .bx-wrapper .bx-caption span {
      font-family: 'NeuePlakText-Regular', sans-serif;
      text-align: left;
      font-size: 12px;
      color: #000;
      line-height: 15px;
      padding: 10px 0; }
      .bx-wrapper .bx-caption span .project-link {
        font-family: 'NeuePlakText-Regular', sans-serif;
        float: right;
        font-size: 18px;
        cursor: pointer; }
        .bx-wrapper .bx-caption span .project-link img {
          height: 18px;
          margin-left: 10px;
          margin-bottom: 2px;
          display: inline;
          vertical-align: middle;
          min-width: auto; }

@media screen and (max-width: 450px) {
  .bx-wrapper .bx-next,
  .bx-wrapper .bx-prev {
    display: none; }
  .bx-wrapper .bx-caption span .project-link {
    font-size: 14px; }
    .bx-wrapper .bx-caption span .project-link img {
      height: 14px; } }

.main-outer {
  /*max-width: 1200px+2*$default-margin;*/
  max-width: 960px;
  margin: auto;
  overflow: hidden;
  margin-top: 80px;
  /*min-height: calc(100vh + 170px);*/
  min-height: 1200px; }

main {
  margin: 30px; }

.col {
  width: 50%;
  width: calc(50% - 15px);
  float: left;
  margin-right: 30px; }
  .col:nth-child(2n + 2) {
    margin-right: 0; }
  .col .team-container {
    display: none; }
  .col .toggle-next {
    cursor: pointer; }

.col2 {
  width: 50%;
  width: calc(50% - 30px);
  float: left;
  margin-right: 30px; }
  .col2:nth-child(2n + 2) {
    margin-right: 0; }

.col3 {
  width: 33.3333%;
  width: calc(33.3333% - 20px);
  float: left;
  margin-right: 30px; }
  .col3:nth-child(3n + 3) {
    margin-right: 0; }
  .col3 .toggle-next {
    cursor: pointer; }

.doc {
  display: flex;
  /*
    span {
        width: 80%;
        width: calc(100% - 40px);
        overflow: hidden;
        text-overflow: ellipsis;
    }*/ }
  .doc svg {
    height: 25px;
    width: 25px;
    margin-right: 10px;
    margin-bottom: 10px;
    vertical-align: middle;
    flex: 30px 0 0; }

.bf {
  font-size: 26px;
  line-height: 34px; }

.mt {
  margin-top: 30px; }

.pt {
  padding-top: 30px; }

.pt2 {
  padding-top: 60px; }

.mb {
  margin-bottom: 30px; }

.mb1 {
  margin-bottom: 15px; }

.mb2 {
  margin-bottom: 60px; }

.mb3 {
  margin-bottom: 60px; }

.ml {
  margin-left: 30px; }

.hm {
  display: initial; }

.tc {
  text-align: center; }

.fr {
  float: right; }

.filterbar {
  /*overflow: hidden;*/
  display: none;
  margin-bottom: 30px; }
  .filterbar.opened {
    display: block; }
  .filterbar .clear-filter {
    float: left;
    padding: 5px 40px 5px 0;
    cursor: pointer; }
  .filterbar ul {
    width: 580px;
    margin: 0 auto;
    padding: 0;
    height: 25px; }
    .filterbar ul li {
      list-style: none;
      position: relative;
      float: left; }
      .filterbar ul li a {
        display: block;
        cursor: pointer;
        padding: 5px;
        padding-right: 40px;
        background: url("select.png") no-repeat 0 7px;
        padding-left: 25px; }
      .filterbar ul li ul {
        display: none;
        position: absolute;
        width: 200px;
        background: #fff;
        z-index: 90;
        top: 25px;
        height: auto; }
        .filterbar ul li ul li {
          display: block;
          width: 100%; }
          .filterbar ul li ul li a {
            background: none;
            cursor: pointer;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 200px; }
      .filterbar ul li:hover ul {
        display: block;
        -webkit-box-shadow: 4px 6px 25px -13px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 4px 6px 25px -13px rgba(0, 0, 0, 0.75);
        box-shadow: 4px 6px 25px -13px rgba(0, 0, 0, 0.75); }

.filtertitle {
  clear: both; }

.only-all {
  clear: left;
  margin-bottom: 15px;
  padding-top: 30px; }
  .only-all.first {
    padding-top: 0; }

.archive-container {
  width: calc(100% + 30px); }

.flex {
  display: flex; }
  .flex.wrap {
    flex-wrap: wrap; }

.top {
  overflow: hidden;
  margin-bottom: 10px; }

.back {
  float: right;
  font-size: 26px;
  line-height: 34px; }

/*
.gallery{
    display: none;
    cursor: pointer;
}*/
.team-members {
  margin-top: 30px;
  column-count: 2;
  column-gap: 30px; }

.arrow {
  display: flex; }
  .arrow:before {
    content: url("/dist/downright.svg");
    flex-basis: 30px;
    width: 25px;
    height: 25px;
    fill: #000;
    margin-right: 10px; }

.contactarrow {
  font-size: 30px; }

.projectsnav {
  margin: auto;
  width: 240px; }
  .projectsnav ul {
    margin: 0;
    padding: 0;
    width: 100%; }
    .projectsnav ul li {
      list-style: none;
      display: inline-block; }
      .projectsnav ul li a {
        display: inline-block;
        text-align: center;
        padding: 10px 15px; }
        .projectsnav ul li a.selected {
          border-bottom: solid 1px #000; }

@media screen and (max-width: 900px) {
  .col {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px; } }

@media screen and (max-width: 750px) {
  .col3 {
    width: 50%;
    width: calc(50% - 15px);
    float: left;
    margin-right: 30px; }
    .col3:nth-child(2n + 2) {
      margin-right: 0; }
    .col3:nth-child(3n + 3) {
      margin-right: 30px; }
  .hm {
    display: none; } }

@media screen and (max-width: 600px) {
  .bf {
    font-size: 20px;
    line-height: 25px; }
  .archive-container {
    width: 100%; }
  .back {
    font-size: 20px;
    line-height: 25px; }
  .col2 {
    width: 100%;
    margin-right: 0; } }

@media screen and (max-width: 530px) {
  .col3 {
    width: 100%;
    float: left;
    margin-right: 0; }
    .col3:nth-child(2n + 2) {
      margin-right: 0; }
    .col3:nth-child(3n + 3) {
      margin-right: 0; }
  .filterbar ul li a {
    padding-right: 20px; }
  .mb2 {
    margin-bottom: 5px; }
  .mb3 {
    margin-bottom: 30px; } }

@media screen and (max-width: 450px) {
  main {
    margin: 10px; }
  .filterbar ul li a {
    padding-right: 10px; }
  .filterbar .clear-filter {
    padding: 5px 20px 5px 5px; } }

.header-outer {
  /*width: 1200px+2*$default-margin;*/
  width: 960px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  z-index: 100;
  background: #fff;
  background: rgba(255, 255, 255, 0.9); }

header {
  position: relative;
  height: 50px;
  margin: 30px 30px 0 30px; }
  header h1 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100; }
    header h1 a, header h1 a:link, header h1 a:visited, header h1 a:active, header h1 a:hover {
      color: black; }
  header .lang-control {
    text-align: center;
    padding: 30px 0;
    color: #666666;
    font-size: 22px; }
    header .lang-control a {
      padding: 0 5px;
      color: #666666; }
      header .lang-control a:visited, header .lang-control a:active {
        color: #666666; }
      header .lang-control a.selected {
        text-decoration: underline;
        text-underline-offset: 5px; }
  header #nav-button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 96;
    font-size: 26px;
    line-height: 34px;
    cursor: pointer; }
    header #nav-button .currnav {
      display: inline; }
    header #nav-button .icon {
      width: 20px;
      height: 20px;
      margin: 0 0 0 10px; }
  header #nav-close-button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 98;
    cursor: pointer; }
    header #nav-close-button .icon {
      width: 30px;
      height: 30px; }
  header nav {
    position: absolute;
    top: 0;
    z-index: 98;
    background: #fff;
    width: 100%;
    display: none; }
    header nav.open {
      display: block; }
    header nav ul {
      padding: 0;
      margin: 0;
      border-top: 1px solid #000;
      margin-top: 70px;
      list-style: none; }
    header nav li a, header nav li a:link, header nav li a:visited, header nav li a:active {
      list-style: none;
      text-align: center;
      font-size: 26px;
      line-height: 34px;
      padding: 20px 0;
      display: block;
      border-bottom: 1px solid #000;
      width: 100%; }
      header nav li a:hover, header nav li a:link:hover, header nav li a:visited:hover, header nav li a:active:hover {
        background: #f2f2f2;
        color: #000; }
    header nav li ul {
      display: none; }

/******************************************************************************/
/*@media screen and (max-width:1250px) {*/
@media screen and (max-width: 960px) {
  .header-outer {
    width: 100%; } }

@media screen and (max-width: 600px) {
  header h1 {
    font-size: 26px;
    line-height: 26px; }
  header #nav-button {
    font-size: 26px;
    line-height: 26px; } }

@media screen and (max-width: 450px) {
  header {
    margin: 10px; }
    header #nav-button .currnav {
      display: none; } }

.footer-outer {
  /*max-width: 1200px+2*$default-margin;*/
  max-width: 960px;
  margin: auto;
  clear: both; }

footer {
  border-top: solid 1px #000;
  padding-top: 30px;
  margin: 30px; }

@media screen and (max-width: 450px) {
  footer {
    margin: 10px; } }
