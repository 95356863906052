.header-outer{
    /*width: 1200px+2*$default-margin;*/
    width: 900px+2*$default-margin;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    top: 0;
    z-index: 100;
    background: #fff;
    background: rgba(255,255,255,0.9);
}
header{
    position: relative;
    height: 50px;
    margin: $default-margin $default-margin 0 $default-margin;
    
    h1{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 100;
        a, a:link, a:visited, a:active, a:hover{
            color: black;
        }
    }
    .lang-control{
        text-align: center;
        padding: 30px 0;
        color: #666666;
        font-size: 22px;
        
        a {
            padding: 0 5px;
            color: #666666;
            
            &:visited, &:active{
                color: #666666;
            }
            
            &.selected{
                text-decoration: underline;
                text-underline-offset: 5px;
            }
        }
    }
    #nav-button{
        position: absolute;
        right: 0;
        top: 0;
        z-index: 96;
        font-size: $font-size1;
        line-height: $line-height1;
        cursor: pointer;
        
        .currnav{
            display: inline;
        }
        
        .icon{
            width: 20px;
            height: 20px;
            margin: 0 0 0 10px;
        }
    }
    #nav-close-button{
        position: absolute;
        right: 0;
        top: 0;
        z-index: 98;
        cursor: pointer;
        
        .icon{
            width: 30px;
            height: 30px;
        }
    }
    
    nav {
        position: absolute;
        top: 0;
        z-index: 98;
        background: $white;
        width: 100%;
        display: none;
        
        &.open{
            display: block;
        }
        
        ul{
            padding: 0;
            margin: 0;
            border-top: 1px solid $black;
            margin-top: 70px;
            list-style: none;
        }
        
        li{
            a, a:link, a:visited, a:active{
                list-style: none;
                text-align: center;
                font-size: $font-size1;
                line-height: $line-height1;
                padding: 20px 0;
                display: block;
                border-bottom: 1px solid $black;
                width: 100%;
                
                &:hover{
                    background: darken($white,5%);
                    color: $black;
                }
            }
            
            ul{
                display: none;
            }
        }
    }
}
/******************************************************************************/
/*@media screen and (max-width:1250px) {*/
@media screen and (max-width:960px) {
    .header-outer{
        width: 100%;
    }
}
@media screen and (max-width:600px) {
    header{
        h1{
            font-size: $font-size0-mobile;
            line-height: $font-size0-mobile;
        }
        #nav-button{
            font-size: $font-size0-mobile;
            line-height: $font-size0-mobile;
        }
    }
}
@media screen and (max-width:450px) {
    header{
        margin: 10px;
        #nav-button {
            .currnav{
                display: none;
            }
        }
    }
}
