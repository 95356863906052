.search{
    border-bottom: solid 1px #666;
    margin-top: $default-margin;
    margin-bottom: $default-margin;
    padding: 10px 0;
    
    img {
        width: 20px;
        height: 20px;
        display: inline-block;
        min-width: auto;
        margin-right: 10px;
    }
    
    input{
        border: 0;
        outline: none;
        font-size: $font-size2;
        width: 80%;
        width: calc(100% - 140px);
    }
    
    .filterbutton{
        float: right;
        cursor: pointer;
  
        svg {
            display: inline-block;
            vertical-align: middle;
            height: 20px;
            width: 20px;
            margin-left: $default-margin/2;
        }
    }
}
.iconmail{
    width: 30px;
    height: 30px;
    fill: #000;
    margin-left: $default-margin/2;
    margin-bottom: -5px;
}
mark{
    background: #ffc1c1;
}