
article{
    &.team{
        width: 100%;
        border-top: solid 1px $black;
        
        h3{
            font-size: $font-size1;
            line-height: $line-height1;
            padding: 5px 0;
            cursor: pointer;
            
            .name{
                margin-right: $default-margin;
            }
            .description{
                font-size: $font-size2;
                line-height: $line-height2;
            }
        }
        .team-content{
            /*border-top: solid 1px $black;*/
            display: none;
            padding: $default-margin/2 0;
            overflow: hidden;
                        
            .left{
                float: left;
                width: 30%;
                margin-right: $default-margin;
                
                img{
                    width: 100%;
                    margin-bottom: $default-margin/2;
                }
            }
            .right{
                float: left;
                width: 66%;
                width: calc(70% - #{$default-margin});
                font-size: $font-size2;
                line-height: $line-height2;
            }
        }
        &.no-border{
            border-top: 0;
        }
    }
}
.team-location-title{
    padding: $default-margin/4 0;
    cursor: pointer;
}
.team-location{
    display: none;
    padding: 0 0 $default-margin/2 0;
    overflow: hidden;
}
.team-group{
    display: none;
}
    

@media screen and (max-width:600px) {
    article{
        &.team{
            h3{
                .name{
                    margin-right: 0;
                    display: block;
                    width: 100%;
                }
                .description{
                    line-height: $line-height2;
                    margin-right: 0;
                    display: block;
                    width: 100%;
                }
            }
            .team-content{
                .left{
                    position: relative;
                    float: none;
                    width: 100%;
                    margin-right:0;
                    padding-top: 30px;
                    
                    img{
                        width: 75%;
                    }
                    a{
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
                .right{
                    float: none;
                    width: 100%;
                    margin-bottom: $default-margin;
                }
            }
        }
    }
}
