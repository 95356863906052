.main-outer{
    /*max-width: 1200px+2*$default-margin;*/
    max-width: 900px+2*$default-margin;
    margin: auto;
    overflow: hidden;
    margin-top: 50px + $default-margin;
    /*min-height: calc(100vh + 170px);*/
    min-height: 1200px;
}
main{
    margin: $default-margin;
}
.col{
    width: 50%;
    width: calc(50% - #{$default-margin/2});
    float: left;
    margin-right: $default-margin;
    
    &:nth-child(2n + 2){
        margin-right: 0;
    }
    .team-container{
        display: none;
    }
    .toggle-next{
        cursor: pointer;
    }
}
.col2{
    width: 50%;
    width: calc(50% - #{$default-margin});
    float: left;
    margin-right: $default-margin;
    
    &:nth-child(2n + 2){
        margin-right: 0;
    }
}
.col3{
    width: 33.3333%;
    width: calc(33.3333% - #{$default-margin/3*2});
    float: left;
    margin-right: $default-margin;
    
    &:nth-child(3n + 3){
        margin-right: 0;
    }
    .toggle-next{
        cursor: pointer;
    }
}
.doc{
    display: flex;

    svg{
        height: 25px;
        width: 25px;
        margin-right: 10px;
        margin-bottom: 10px;
        vertical-align: middle;
        flex: 30px 0 0;
    }
    /*
    span {
        width: 80%;
        width: calc(100% - 40px);
        overflow: hidden;
        text-overflow: ellipsis;
    }*/
}

.bf{
    font-size: $font-size1;
    line-height: $line-height1;
}
.mt{
    margin-top: $default-margin;
}
.pt{
    padding-top: $default-margin;
}
.pt2{
    padding-top: $default-margin*2;
}
.mb{
    margin-bottom: $default-margin;
}
.mb1{
    margin-bottom: $default-margin-half;
}
.mb2{
    margin-bottom: $default-margin*2;
}
.mb3{
    margin-bottom: $default-margin*2;
}
.ml{
    margin-left: $default-margin;
}
.hm{
    display: initial;
}
.tc{
    text-align: center;
}
.fr{
    float: right;
}
.filterbar{
    /*overflow: hidden;*/
    display: none;
    
    &.opened{
        display: block;
    }
    
    margin-bottom: $default-margin;
    .clear-filter{
        float: left;
        padding: 5px 40px 5px 0;
        cursor: pointer;
    }
    ul {
        width: 580px;
        margin: 0 auto;
        padding: 0;
        height: 25px;
        
        li {
            list-style: none;
            position: relative;
            float: left;

            a{
                display: block;
                cursor: pointer;
                padding: 5px;
                padding-right: 40px;
                background: url('select.png') no-repeat 0 7px;
                padding-left: $default-padding - 5px;
           }
            ul{
                display: none;
                position: absolute;
                width: 200px;
                background: #fff;
                z-index: 90;
                top: 25px;
                height: auto;

                
                li {
                    display: block;
                    width: 100%;
                    
                    a{
                        background: none;
                        cursor: pointer;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 200px;
                    }
                }
            }
            &:hover{
                ul{
                    display: block;
                    -webkit-box-shadow: 4px 6px 25px -13px rgba(0,0,0,0.75);
                    -moz-box-shadow: 4px 6px 25px -13px rgba(0,0,0,0.75);
                    box-shadow: 4px 6px 25px -13px rgba(0,0,0,0.75);                
                }
            }
        }
    }
}
.filtertitle{
    clear: both;
}
.only-all{
    clear: left;
    margin-bottom: 15px;
    padding-top: 30px;
    
    &.first {
        padding-top: 0;
    }
}
.archive-container{
    width: calc(100% + #{$default-margin});
}
.flex{
    display: flex;
    &.wrap{
        flex-wrap: wrap;
    }
}
.top{
    overflow: hidden;
    margin-bottom: $default-margin/3;
}
.back{
    float: right;
    font-size: $font-size1;
    line-height: $line-height1;
}
/*
.gallery{
    display: none;
    cursor: pointer;
}*/
.team-members{
    margin-top: $default-margin;
    column-count: 2;
    column-gap: $default-margin;
}
.arrow{
    display: flex;
    
    &:before{
        content:url('/dist/downright.svg');
        flex-basis: 30px;
        width: 25px;
        height: 25px;
        fill: #000;
        margin-right: 10px;
        
    }
}
.contactarrow{
    font-size: 30px;
}
.projectsnav{
    margin: auto;
    width: 240px;
    
    ul{
        margin: 0;
        padding: 0;
        width: 100%;
        
        li{
            list-style: none;
            display: inline-block;
            
            a {
                display: inline-block;
                text-align: center;
                padding: 10px 15px;
                &.selected{
                    border-bottom: solid 1px $black;
                }
            }
        }
    }
}

@media screen and (max-width:900px) {
    .col{
        width: 100%;
        margin-right: 0;
        margin-bottom: $default-margin;
        
    }
}
@media screen and (max-width:750px) {
    .col3{
        width: 50%;
        width: calc(50% - #{$default-margin/2});
        float: left;
        margin-right: $default-margin;

        &:nth-child(2n + 2){
            margin-right: 0;
        }
        &:nth-child(3n + 3){
            margin-right: $default-margin;
        }
    }
    .hm{
        display: none;
    }
}
@media screen and (max-width:600px) {
    .bf{
        font-size: $font-size1-mobile;
        line-height: $line-height1-mobile;
    }
    .archive-container{
        width: 100%;
    }
    .back{
        font-size: $font-size1-mobile;
        line-height: $line-height1-mobile;
    }
    .col2{
        width: 100%;
        margin-right: 0;
    }
}
@media screen and (max-width:530px) {
    .col3{
        width: 100%;
        float: left;
        margin-right: 0;

        &:nth-child(2n + 2){
            margin-right: 0;
        }
        &:nth-child(3n + 3){
            margin-right: 0;
        }
    }
    .filterbar{
        ul {
            li {
                a{
                    padding-right: 20px;
               }
            }
        }
    }
    .mb2{
        margin-bottom: 5px;
    }
    .mb3{
        margin-bottom: $default-margin;
    }

}
@media screen and (max-width:450px) {
    main{
        margin: 10px;
    }
    .filterbar{
        ul {
            li {
                a{
                    padding-right: 10px;
               }
            }
        }
    }
    .filterbar .clear-filter {
        padding: 5px 20px 5px 5px;
    }
}
