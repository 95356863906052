body{
    font-family: 'HelveticaNeueLTPro-Roman', sans-serif;
    font-weight: 400;
    background: $white;
    line-height: $line-height2;
    font-size: $font-size2;
}
strong,b{
    font-weight: 800;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
    line-height: normal;
}

h1, .h1 { 
    font-size: $font-size1; 
    line-height: $line-height1;
    font-weight: 400; 
}
.underline2{
    border-bottom: solid 2px $black;
}
.topline{
    border-top: solid 1px $black;
}
.topline2{
    border-top: solid 2px $black;
}

h2, .h2 { 
    font-size: $font-size1; 
    font-weight: 400; 
    line-height: $line-height1;
    padding-top: 5px;
    
    &.small{
        font-size: $font-size2;
        font-weight: normal;
    }
    small{
        font-size: $font-size2;
        font-weight: normal;
    }
    &.underline{
        border-bottom: solid 1px $black;
    }
    &.underline2{
        border-bottom: solid 2px $black;
    }
    &.topline{
        border-top: solid 1px $black;
        &.first{
            border-top: 0;
        }
    }
    &.topline2{
        border-top: solid 2px $black;
    }
    &.teambar{
        a {
            float: right;
            svg{
                height: 20px;
                width: 20px;
                margin-left: 10px;
            }
        }
    }
}
em,
i,
.italic {
    font-style: italic;
}

strong,
b,
.bold {
    font-weight: 700;
}

img {
    display: block;
    max-width: 100%;
    min-width: 100%
}
p{
    /*margin-bottom: $default-margin;*/
    margin-bottom: 0;
    &:last-child{
        margin-bottom: 0;
    }
}
a, a:link, a:visited, a:active{
    color: $default-link-color;
    text-decoration: none;
    
    &:hover{
        /*color: lighten($default-link-color, 30);*/
        text-decoration: none;
    }
}

.titel_einzug{
    margin-left: 20px;
}
.proj-label{
    vertical-align: top;
    white-space: nowrap;
    width: 155px;
}
.cb{
    clear: both;
}
.pointer{
    cursor: pointer;
}
.footer-img{
    height: 45px;
    width: auto;
    min-width: auto;
}
@media screen and (max-width:600px) {
    body{
        line-height: $line-height2-mobile;
        font-size: $font-size2-mobile;
    }
    h1, .h1 { 
        font-size: $font-size1-mobile; 
        line-height: $line-height1-mobile;
    }
    h2, .h2 { 
        font-size: $font-size1-mobile; 
        line-height: $line-height1-mobile;
    }
}
