.bx-wrapper{
    box-shadow: none;
    .bx-controls-direction a {
        width: 30px;
        height: 150px;
        margin-top: -100px;
    }
    img {
        margin:auto;
        /*margin: 0 auto 50px auto;*/
    }
    .bx-next {
        right: -30px;
        background: url('/dist/right.svg') no-repeat 50% 50%;
        &:hover, &:focus{
            background-position: 30% 50%;
        }
        
    }
    .bx-prev {
        left: -30px;
        background: url('/dist/left.svg')  no-repeat 50% 50%;
        &:hover, &:focus{
            background-position: 70% 50%;
        }
        
    }
    .bx-next,
    .bx-prev {
        background-size: 30px 30px;
        background-color: transparent;
        width: 30px;
        height: 30px;
    }
    .bx-pager{
        display: none;
        padding-top: 0;
    }
    .bx-caption{
        font-family: 'NeuePlakText-Regular', sans-serif;
        background: #fff;
        /*height: 50px;*/
        position: relative;
        overflow: hidden;
        
        span{
            font-family: 'NeuePlakText-Regular', sans-serif;
            text-align: left;
            font-size: 12px;
            color: #000;
            line-height: 15px;
            padding: 10px 0;
            
            .project-link{
                font-family: 'NeuePlakText-Regular', sans-serif;
                float: right;
                font-size: 18px;
                cursor: pointer;
                
                img {
                    height: 18px;
                    margin-left: 10px;
                    margin-bottom: 2px;
                    display: inline;
                    vertical-align: middle;
                    min-width: auto;
                }
            }
        }
    }
}
@media screen and (max-width:450px) {
    .bx-wrapper{
        .bx-next,
        .bx-prev {
            display: none;
        }
        .bx-caption{

            span{
                .project-link{
                    font-size: 14px;
                    img {
                        height: 14px;
                    }
                }
            }
        }
    }
}