article{
    &.projects{
        width: 45%;
        width: calc(50% - #{$default-margin/2});
        margin: 0 $default-margin $default-margin 0;
        float: left;
        position: relative;
        
        .image-container{
            background-color: #DCDCDC;
            background-position: center;
            background-size: cover;
            width: 100%;
            height: 300px;
            margin-bottom: $default-margin-half;
            &.grouplink {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $font-size1;
                line-height: $line-height1;
                color: $white;
                text-align: center;
            }
        }
        h2{
            font-size: $font-size1;
            line-height: $line-height1;
            width: 100%
        }
        &:nth-of-type(2n + 2){
            margin-right: 0;
        }
    }
    &.projects-index{       
        height: auto;
        margin: 0 $default-margin $default-margin 0;
        border-top: solid 2px $black;
        
        .image-container{
            position: absolute;
            bottom: 0;
            background-color: #DCDCDC;
            background-position: center;
            background-size: cover;
            width: 100%;
            height: 275px;
            margin-bottom: 0;
            margin-top: $default-margin-half;
        }
        
        h2{
            font-size: $font-size1;
            line-height: $line-height1;
            margin: $default-margin/3 0 $default-margin/6 0;
            /*
            width: 80%;
            width: calc(100% - 100px);
            */
        }
        /*
        .arrow{
            width: 100px;
            position: absolute;
            bottom: -24px;
            right: 0;
            display: none;
        }*/
        .lead{
            /*
            width: 80%;
            width: calc(100% - 100px);
            */
            margin-bottom: 285px;
        }
        /*
        &:hover, &.simulate-hover{
            -webkit-user-select: none;
            -webkit-touch-callout: none;    
            
            .arrow{
                display: block;
            }
        }*/
    }
    &.projects-archive{       
        width: 22%;
        width: calc(25% - #{$default-margin});
        height: auto;
        margin: 0 $default-margin $default-margin-half 0;
        float: left;
        position: relative;
        
        .image-container{
            background-color: #DCDCDC;
            background-position: center;
            background-size: cover;
            width: 100%;
            height: 140px;
            margin-bottom: $default-margin-half/2;
        }
        h2{
            width: 100%;
            font-size: $font-size2;
            line-height: $line-height2 - 2px;
        }
        &:nth-child(2n + 2){
            margin-right: $default-margin;
        }
        /*
        &:nth-child(4n + 4){
            margin-right: 0;
        }
        */
    }
    &.projects-detail,
    &.news-detail{
        img {
            margin: auto;
            margin-bottom: $default-margin;
        }
        h2{
            margin-bottom: $default-margin;
        }
        .info{
            padding: 20px 0;
            display: inline-block;
            font-size: 36px;
            
            svg{
                height: 27px;
                width: 27px;
            }
        }
        p {
            &.description{
                column-count: 2;
                column-gap: $default-margin*2;
                margin-bottom: $default-margin*2;
                /*display: none;*/
            }
            &.docs{
                /*margin-top: -$default-margin;*/
                margin-bottom: $default-margin*2                
            }
        }
    }
    &.events{
        display: flex;
        /*border-bottom: solid 1px $black;*/
        padding-bottom: $default-margin;
        /*margin-bottom: $default-margin;*/
        position: relative;
        padding-top: 18px;

        &:before{
            content: '';
            width: 8%;
            height: 3px;
            background: $black;
            position: absolute;
            top: 0;
            left: 0;
        }
        
        
        .img {
            flex: 200px 0 0;
            margin-right: $default-margin;
            margin-top: 7px;
            .image-container{
                background-color: #DCDCDC;
                background-position: center;
                background-size: cover;
                width: 100%;
                height: 140px;
                margin-bottom: $default-margin-half/2;
            }
        }
        .event{
            flex-grow: 2;
            margin-right: $default-margin;
            
            h2 {
                margin-bottom: 10px;
            }
        }
        .date{
            flex: 200px 0 0;
            margin-right: $default-margin;
            margin-top: 45px;
            h3 {
                margin-bottom: 10px;
            }
            p{
                margin-bottom: 10px;
            }
        }
    }
    .fixed-row{
        width: 100%;
        display: block;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    &.news{
        background: $white;
        width: 33%;
        width: calc(33% - #{$default-margin});
        margin: 0 $default-margin $default-margin 0;
        float: none;
        
        h2{
            position: relative;
            /*padding-top: 18px;*/
            padding-top: 5px;
            margin-bottom: $default-margin;
            
            em {
                font-size: $font-size2;
            }
            
            &:before{
                content: '';
                width: 15%;
                height: 3px;
                background: $black;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        img{
            margin-bottom: $default-margin-half;      
            min-width: 100%;
            max-width: 100%;
        }
        &:nth-child(3n + 3){
            margin-right: 0;
        }
    }
    &.default{
        h2{
            margin-bottom: $default-margin;
        }
        p {
            font-size: $font-size2;
            /*margin-bottom: $default-margin;*/
            a{
                text-decoration: underline;
                cursor: pointer;
            }
        }
        img {
            margin: auto;
            margin-bottom: $default-margin;
        }
        label{
            font-size: $font-size3;
            position: relative;
            span{
                top: -45px;
                position: absolute;
                display: block;
                white-space: nowrap;
            }
        }
    }
}
.grid{
    width: calc(100% + #{$default-margin});
    overflow: hidden;
}
.gallery{
    p{
        font-size: $font-size2;
        line-height: $line-height2;
    }
}
.network{
    align-items: center; 
    
    a{
        margin-right: $default-margin;
        flex: 320px 0 0;
    }
    img {
        width: 320px;
        
    }
    padding-bottom: $default-margin;
    border-bottom: solid 1px #000;
    
    &:last-child{
        border-bottom: 0;
    }
}

/******************************************************************************/
/*
@media screen and (max-width:1250px) {
    article{
        &.projects{       
            height: 36vw;
            
            .image-container{
                height: 34vw;
                height: calc(40vw - 135px);
            }
        }
        &.projects-index{       
            height: 28vw;
            
            .image-container{
                height: 19vw;
                height: calc(21vw - 60px);
                width: 22vw;
            }

        }
        &.projects-archive{       
            height: 22.5vw;

            .image-container{
                height: 19vw;
                height: calc(21vw - 60px);
            }
        }
    }
}
@media screen and (max-width:1000px) {
    article{
        &.projects-index{       
            height: 31vw;
        }
        &.projects-archive{       
            width: 33%;
            width: calc(33.3333% - #{$default-margin});
            height: 29vw;
            
            .image-container{
                height: 25vw;
                height: calc(27vw - 60px);
            }
            
            &:nth-child(4n + 4){
                margin-right: $default-margin;
            }
            &:nth-child(3n + 3){
                margin-right: 0;
            }
        }
    }
}
@media screen and (max-width:900px) {
    article{
        &.projects-index{       
            height: 36vw;
        }
        &.news{
            width: 50%;
            width: calc(50% - #{$default-margin});

            &:nth-child(3n + 3){
                margin-right: $default-margin;
            }
            &:nth-child(2n + 2){
                margin-right: 0;
            }
        }
    }
}
*/
@media screen and (max-width:960px) {
    article{
        &.projects{
            .image-container{
                height: 33vw;
                height: calc(35vw - #{$default-margin*1.5});
            }
        }
        &.projects-index{       
            /*height: 28vw;*/
            
            .image-container{
                height: 30vw;
                height: calc(33vw - #{$default-margin*1.5});
                /*width: 22vw;
                width: calc(28vw - #{$default-margin*1.5});*/
            }
            .lead{
                margin-bottom: 30vw;
                margin-bottom: calc(33vw - #{$default-margin*1.5} + 10px);
            }
        }
        &.projects-archive{       
            width: 33%;
            width: calc(33.3333% - #{$default-margin});
            
            .image-container{
                height: 25vw;
                height: calc(27vw - 60px);
            }
            
            &:nth-child(4n + 4){
                margin-right: $default-margin;
            }
            &:nth-child(3n + 3){
                margin-right: 0;
            }
        }
        &.news{
            width: 50%;
            width: calc(50% - #{$default-margin});

            &:nth-child(3n + 3){
                margin-right: $default-margin;
            }
            &:nth-child(2n + 2){
                margin-right: 0;
            }
        }
    }
}
@media screen and (max-width:800px) {
    article{
        &.events{
            flex-wrap: wrap;
            .img {
                order: 2;
                flex: 33% 0 0;
                flex: calc(33% - #{$default-margin}) 0 0;
                .image-container{
                    height: 19vw;
                }
            }
            .event{
                flex: 66% 0 0;
                flex: calc(66% - #{$default-margin}) 0 0;
            }
            .date{
                order: 3;
                flex: 66% 0 0;
                flex: calc(66% - #{$default-margin}) 0 0;
            }
        }
    }
}

@media screen and (max-width:750px) {
    article{
        /*
        &.projects{
            width: 100%;
            height: 67vw;
            
            .image-container{
                height: 73vw;
                height: calc(73vw - 135px);
            }
        }
        &.projects-index{       
            height: auto;
            
            .image-container{
                position: relative;
                height: 34vw;
                width: 47vw;
            }
        }*/
        &.projects-archive{       
            width: 50%;
            width: calc(50% - #{$default-margin});
            
            .image-container{
                height: 38vw;
                height: calc(40vw - 60px);
            }
            &:nth-child(3n + 3){
                margin-right: $default-margin;
            }
            /*
            &:nth-child(3n + 3){
                margin-right: $default-margin;
            }
            &:nth-child(2n + 2){
                margin-right: 0;
            }*/
        }
        &.projects-detail,
        &.news-detail{
            p {
                &.description{
                    column-count: 1;
                    column-gap: 0;
                }
            }
        }
    }
}
@media screen and (max-width:600px) {
    article{
        &.projects{
            width: 100%;
            margin-right: 0;
            /*height: 70vw;*/
            
            .image-container{
                height: 90vw;
                height: calc(90vw - 135px);
            }
            h2{
                font-size: $font-size1-mobile;
                line-height: $line-height1-mobile;
            }
            &:nth-child(2n + 2){
                margin-right: 0;
            }
        }
        &.projects-index{       
            height: auto;
            margin-right: 0;

            h2{
                font-size: $font-size1-mobile;
                line-height: $line-height1-mobile;
            }            
            .image-container{
                position: relative;
                height: 68vw;
                height: calc(70vw - #{$default-margin*2});
                /*width: 47vw;
                width: calc(53vw - #{$default-margin*2});*/
            }
            .lead{
                margin-bottom: 0;
            }

        }
        &.projects-archive{
            margin-right: 0;
            height: auto;
            
            .image-container{
                height: 69vw;
                height: calc(72vw - #{$default-margin*2});
            }
            h2{
                font-size: $font-size1-mobile;
                line-height: $line-height1-mobile;
            }
            &:nth-child(2n + 2){
                margin-right: 0;
            }
            &:nth-child(3n + 3){
                margin-right: 0;
            }
        }
        &.news{
            width: 100%;
            margin-right: 0;
        }
        &.events{
            flex-wrap: wrap;
            .img {
                order: 3;
                flex: 100% 0 0;
                .image-container{
                    height: 62vw;
                }
            }
            .event{
                flex: 100% 0 0;
            }
            .date{
                order: 2;
                flex: 100% 0 0;
            }
        }
    }
    .grid{
        width: 100%;
    }
}
@media screen and (max-width:500px) {
    article{
        &.projects{
            width: 100%;
            /*height: 73vw;*/
            
            .image-container{
                height: 100vw;
                height: calc(100vw - 135px);
            }
        }
        &.projects-index{           
            height: auto;
            
            h2{
                width: calc(100% - 60px);
            }

            .image-container{
                height: 73vw;
                height: calc(75vw - #{$default-margin*2});
            }
            .arrow{
                width: 60px;
            }
        }

        &.projects-archive{       
            margin-right: 0;
            
            .image-container{
                height: 70vw;
                height: calc(72vw - #{$default-margin*2});
            }
            
        }
    }
}
@media screen and (max-width:450px) {
    article{
        &.projects{
            .image-container{
                height: 105vw;
                height: calc(105vw - 135px);
            }
        }
        &.projects-archive{                   
            .image-container{
                height: 70vw;
                height: calc(72vw - #{$default-margin-half*2});
            }
        }
        &.projects-index{           
            .image-container{
                height: 78vw;
                height: calc(80vw - #{$default-margin*2});
            }
        }
    }
}

