////////////////////////////////////////////////////////////////////////////////
// Colors
////////////////////////////////////////////////////////////////////////////////

$white:                         #fff !default;
$black:                         #000 !default;

////////////////////////////////////////////////////////////////////////////////
// Base
////////////////////////////////////////////////////////////////////////////////
$body-bg:                       $white !default;
$default-font-color:            $black !default;

$default-margin:                30px;        
$default-margin-half:           $default-margin / 2;
$default-padding:               30px;               
$default-padding-half:          $default-padding / 2;

$default-link-color:            $black;


$font-size1:                    26px;
$line-height1:                  34px;
$font-size2:                    16px;
$line-height2:                  20px;
$font-size3:                    12px;

$font-size0-mobile:             26px;
$line-height0-mobile:           34px;
$font-size1-mobile:             20px;
$line-height1-mobile:           25px;
$font-size2-mobile:             16px;
$line-height2-mobile:           23px;
