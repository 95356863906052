.footer-outer{
    /*max-width: 1200px+2*$default-margin;*/
    max-width: 900px+2*$default-margin;
    margin: auto;
    clear: both;
}
footer{
    border-top: solid 1px #000;
    padding-top: $default-margin;
    margin: $default-margin;
}
@media screen and (max-width:450px) {
    footer{
        margin: 10px;
    }
}
